import { ReactiveControllerHost } from "lit"
import { GenerativeModel, GoogleGenerativeAI } from "@google/generative-ai"
import { Chat } from "./chat"
import { Task, TaskStatus } from "@lit/task"

export class ChatController {
    private _chat: Chat
    host: ReactiveControllerHost
    private _model: GenerativeModel
    private _generateResonseTask: Task<[string], void>
    private _chatHistory: Chat[] = []
    private _isInitialized = false

    constructor(host: ReactiveControllerHost, modelName: string) {
        this.host = host
        this._chat = {query: "", response: ""}

        // initialize model

        const API_KEY = import.meta.env.VITE_GEMINI_API_KEY;

        const genAI = new GoogleGenerativeAI(API_KEY);
        // For text-only input, use the gemini-pro model
        this._model = genAI.getGenerativeModel({ model: modelName });

        // setup task
        this._generateResonseTask = new Task<[string], void>(host,
            async ([]: [string]): Promise<void> =>{
                await this.generateResponse()
            },
            () => [this.query]
        )
    }


    public get query() : string {
        return this._chat.query
    }

    public get response() : string {
        return this._chat.response
    }

    public get chatHistory() : Chat[] {
        return this._chatHistory
    }



    public get responseRequestStatus() : TaskStatus {
        return this._generateResonseTask.status
    }


    async updateQuery(text: string)  {
        if (text === "") {return}

         if (this._generateResonseTask.status === TaskStatus.PENDING) {
            return
        }

        this._chat.query = text


        this.host.requestUpdate()
    }

    async generateResponse(): Promise<void> {


        if (this._chat.query === "") return

        let response = ""
        const result = await this._model.generateContentStream([this._chat.query]);

        for await (const chunk of result.stream) {
          const chunkText = chunk.text();
            response += chunkText
          //this._chat.response += chunkText;
        }

        if (!this._isInitialized) {
            this._isInitialized = true
            return
        }
        this._chatHistory.push({
            query: this._chat.query,
            response: response
        })

        this.host.requestUpdate()
    }


}