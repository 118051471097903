import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('app-privacy')
export class AppPrivacy extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
        padding: 1rem;
      }

      h1 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 20px;
            margin-top: 20px;
        }
        p {
            margin-bottom: 15px;
        }
        ul {
            margin-bottom: 15px;
            padding-left: 20px;
        }
        li {
            margin-bottom: 5px;
        }
    `,
  ];

  render() {
    return html`<div>
      <h1>
        Privacy Policy for YouChat - Chat about YouTube using Generative AI
      </h1>

      <!-- <p><strong>Effective Date:</strong> [Date]</p> -->

      <h2>1. Information Collection and Use</h2>
      <ul>
        <li>
          <strong>Information You Provide:</strong> We may collect and store
          information that you voluntarily provide to us when you use the App.
          This may include personal information such as your name, email
          address, profile picture, and any other information you choose to
          share.
        </li>
        <li>
          <strong>Automatically Collected Information:</strong> We may
          automatically collect certain information when you use the App,
          including but not limited to device information (such as device model,
          operating system version), log data, and usage information.
        </li>
        <li>
          <strong>Chat Content:</strong> The App may analyze and process chat
          conversations to provide generative AI responses. Your chat content
          may be collected and stored for this purpose.
        </li>
      </ul>

      <h2>2. Use of Information</h2>
      <p>We may use the collected information for the following purposes:</p>
      <ul>
        <li>To provide and maintain the App;</li>
        <li>To personalize your experience and improve the App;</li>
        <li>
          To communicate with you, respond to your inquiries, and provide
          customer support;
        </li>
        <li>To detect, prevent, and address technical issues;</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>3. Sharing of Information</h2>
      <p>
        We may share your information with third parties in the following
        circumstances:
      </p>
      <ul>
        <li>With service providers who assist us in operating the App;</li>
        <li>
          With analytics providers to analyze usage patterns and improve the
          App;
        </li>
        <li>When required by law or in response to valid legal requests.</li>
      </ul>

      <h2>4. Data Retention</h2>
      <p>
        We will retain your personal information only for as long as necessary
        to fulfill the purposes outlined in this Privacy Policy and as required
        by applicable laws and regulations.
      </p>

      <h2>5. Security</h2>
      <p>
        We take reasonable measures to protect the security of your personal
        information. However, no method of transmission over the internet or
        electronic storage is 100% secure.
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        The App is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13. If you
        are a parent or guardian and become aware that your child has provided
        us with personal information, please contact us.
      </p>

      <h2>7. Changes to this Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Any changes will be
        posted on this page, and the effective date will be updated accordingly.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at
        <a href="mailto:mail@siliconwolf.co">mail@siliconwolf.co</a>.
      </p>
    </div>`;
  }
}

