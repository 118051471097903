import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@material/web/elevation/elevation';
import logo from '../assets/icons/512x512.png'

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'YouChat';

  @property({ type: Boolean }) enableBack: boolean = false;

  static styles = css`
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* background: var(--app-color-primary); */
      color: white;
      padding: 12px;
      padding-top: 4px;

      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      height: env(titlebar-area-height, 30px);
      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;

      height: 3.5em;
      /* background-color: red; */
      transition-duration: 250ms;
       transition-timing-function: ease-in-out;
       box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    img {
      display: inline-block;
      width: 3rem;
      height: 3rem;
    }

    .level1 {
      --md-elevation-level: 3;
    }

    header h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: bold;
    }

    nav a {
      margin-left: 10px;
    }

    #back-button-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    @media (prefers-color-scheme: light) {
      header {
        color: black;
      }

      nav a {
        color: initial;
      }
    }
  `;

  render() {
    return html`
      <header class="level1">
        <div id="back-button-block">
          ${this.enableBack
            ? html`<sl-button size="small" href="${resolveRouterPath()}">
                Back
              </sl-button>`
            : null}

          <img src=${logo}>
        </div>
      </header>
    `;
  }
}

