import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/checkbox/checkbox.js';

import '../components/header';
import '../components/prompt-footer';
import '../components/user-query';
import '../components/model-response';
import { styles } from '../styles/shared-styles';
import { provide } from '@lit/context';
import { chatContext } from '../contexts/chat-context';
import { Chat } from '../models/chat';
import { ChatController } from '../models/chat-controller';
import { TaskStatus } from '@lit/task';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('app-home')
export class AppHome extends LitElement {
  @provide({ context: chatContext })
  @property({ attribute: false })
  chat: Chat = { query: '', response: '' };
  private chatController = new ChatController(this, 'gemini-pro');

  @state()
  private _draft: Chat = {query: "", response:""}

  static styles = [
    styles,
    css`

      app-header {
        display: block;
        height: 72px;
        background-color: white
      }

      user-query {
        margin-bottom: 1rem;
      }

      prompt-footer {
        color: black;
      }
    `,
  ];

  constructor() {
    super();
  }

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');

  }

  connectedCallback(): void {
    super.connectedCallback()
    this.chatController.updateQuery(
      'You are YouChat and you are only answer questions related to Youtube. All answers must be presented in a user-friendly format'
    );
  }



  public get shouldShowProgress() : boolean {
    return this.chatController.responseRequestStatus === TaskStatus.PENDING ||
           this.chatController.responseRequestStatus === TaskStatus.ERROR
  }


  render() {

    let styles = {display: this.shouldShowProgress ? "block" : "none"}
    let modelResponseContent =  this.chatController.query === '' ? null : (
      html `
       <model-response
            class="rubik-body"
            status=${this.chatController.responseRequestStatus}
            response=""
            style=${styleMap(styles)}
          ></model-response>`
    )

    return html`
      <app-header></app-header>

      <main>
        ${this.chatController.chatHistory.map((chat) => {
          return html`
            ${this.chatController.query !== ''
              ? html`
                  <user-query
                    class="rubik-body"
                    query=${chat.query}
                  ></user-query>
                `
              : null}

            <model-response
              class="rubik-body"
              status=${TaskStatus.COMPLETE}
              response=${chat.response}
            ></model-response>
          `;
        })}
        <div>
          <!-- ${this.chatController.query !== ''
            ? html`
                <user-query
                  class="rubik-body"
                  query=${this._draft.query}
                ></user-query>
              `
            : null} -->

         ${modelResponseContent}
        </div>
      </main>
      <prompt-footer
        ?shouldReset=${!this.shouldShowProgress}
        @user-query-changed=${(query: CustomEvent<string>) => {
          if (query !== null) {
            this.chatController.updateQuery(query.detail.trim());
          }
        }}
        class="rubik-body"
      ></prompt-footer>
    `;
  }
}

