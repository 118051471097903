import { LitElement, html, css, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { chatContext } from '../contexts/chat-context';
import { Chat } from '../models/chat';
import { consume } from '@lit/context';

import '@material/web/button/filled-button.js';
import '@material/web/textfield/outlined-text-field';
import '@material/web/iconbutton/filled-icon-button';
import '@material/web/icon/icon';

import { styles } from '../styles/shared-styles';
import { MdOutlinedTextField } from '@material/web/textfield/outlined-text-field';

@customElement('prompt-footer')
export class PromptFooter extends LitElement {
  @consume({ context: chatContext, subscribe: true })
  chat?: Chat;

  @property({type: Boolean})
  shouldReset = false

  private value: string = '';

  static styles = [
    styles,
    css`
      :host {
        display: block;
        background-color: white;
        padding: 1rem;
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        color: blue;
      }

      md-outlined-text-field {
        resize: vertical;
        width: 87.5%;
        --md-outlined-text-field-container-shape: 16px;
        --md-outlined-text-field-input-text-font: "Rubik";
      }

      #prompt-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .footnote {
        margin-top: 1rem;
        padding-inline: 1rem;
      }

      /* md-icon {
                --md-icon-size: 1rem;
            } */
    `,
  ];



  protected updated(_changedProperties: PropertyValueMap<this> | Map<PropertyKey, unknown>): void {
        if (this.shouldReset) {
            (this.renderRoot?.querySelector('#text-field') as MdOutlinedTextField).reset()
        }
  }

  render() {
    return html`
      <div id="prompt-footer">

        <div id="prompt-container">
          <md-outlined-text-field

            id="text-field"
            class="rubik-body"
            @input=${(event: Event) => {
              this.value = (event.target as HTMLInputElement).value;

              //if (!event.data) return
              //  this.value += event.data
              if (this.chat) {
                //     this.chat.query += event.data
              }
            }}
            hasTrailingIcon
            placeholder="Ask a question on YouTube here"
            type="textarea"
          >
          </md-outlined-text-field>
          <div id="controls-container">
            <md-filled-icon-button
              @click=${() => {
                if (this.value === '') {
                  return;
                }
                this.dispatchEvent(
                  new CustomEvent('user-query-changed', { detail: this.value })
                );
              }}
            >
              <md-icon>send</md-icon>
            </md-filled-icon-button>
          </div>
        </div>
        <div class="rubik-footnote footnote">YouChat may display inaccuate info</div>
      </div>
    `;
  }
}

