import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '@material/web/iconbutton/filled-icon-button';
import '@material/web/icon/icon';


@customElement('user-query')
export class UserQuery extends LitElement {



  @property({ type: String })
  query = '';

  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;

      }

      .user-profile-picture {
        display: flex;
        justify-content: space-between;
      }

      md-filled-icon-button {
        visibility: hidden;
      }

        md-icon[filled] {
                --md-icon-size: 2rem;
                color: red;
            }

    `,
  ];

  render() {
    return html`
      <div class="user-profile-picture">
          <md-icon filled>account_circle</md-icon>
        <!-- <img src="" alt="user-profile-photo"> -->
        <md-filled-icon-button>
          <md-icon>edit</md-icon>
        </md-filled-icon-button>
      </div>
      <div>${this.query}</div>
    `;
  }
}

