import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import AIPhoto from '..//assets/icons/icon_48.png'
import '../styles/global.css';
import { TaskStatus } from '@lit/task';

@customElement('model-response')
export class ModelResponse extends LitElement {

  @property({type: Number})
  status = 0 //TaskStatus.INITIAL

  @property({type: String})
  response = ""

  static styles = [
    css`
      :host {
        display: block;
      }

      .response-container {
        display: flex;
        justify-content: space-between;
      }

      .presented-response-container {
        text-align: left;
        margin-top: 0.5em;
      }

      img {
        width: 2rem;
        height: 2rem;
      }
    `,
  ];

  render() {
    console.log(this.response)
    let content = html``
    if (this.status === TaskStatus.PENDING) {
        content = html`<div>Loading</div>`
    } else if (this.status === TaskStatus.ERROR) {
        content = html`<div>Something went wrong. Try again</div>`
    } else {
        content = html`
        <div class="response-container rubik-body">
          <img src=${AIPhoto} alt="ai-profile-photo" />
        </div>
        <div class="presented-response-container paint">
           ${this.response}
        </div>
      `;
    }
    return content
  }
}

